var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _vm.data
        ? _c(
            "el-descriptions",
            {
              attrs: {
                title: "Order Deatils",
                "label-style": { "font-weight": "bold" }
              }
            },
            [
              _c("el-descriptions-item", { attrs: { label: "Order ID" } }, [
                _vm._v(" " + _vm._s(_vm.data.id) + " ")
              ]),
              _c(
                "el-descriptions-item",
                { attrs: { label: "Order Reference" } },
                [_vm._v(" " + _vm._s(_vm.data.orderReference) + " ")]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "Taxed amount" } },
                [_c("inr", { attrs: { number: _vm.data.taxedAmount } })],
                1
              ),
              _c("el-descriptions-item", { attrs: { label: "Status" } }, [
                _vm._v(" " + _vm._s(_vm.data.status) + " ")
              ]),
              _c("el-descriptions-item", { attrs: { label: "Placed On" } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm._f("moment")(
                        _vm.data.orderTimestamp,
                        "DD-MM-YYYY hh:mm a"
                      )
                    ) +
                    " "
                )
              ]),
              _c(
                "el-descriptions-item",
                { attrs: { label: "Freight charge" } },
                [_c("inr", { attrs: { number: _vm.data.freightCharges } })],
                1
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "Total Quantity" } },
                [_vm._v(" " + _vm._s(_vm.data.totalQuantity) + " ")]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "Total Product(s)" } },
                [_vm._v(" " + _vm._s(_vm.data.totalProducts) + " ")]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "Sub Total" } },
                [_c("inr", { attrs: { number: _vm.data.total } })],
                1
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "Delivery Option" } },
                [_vm._v(" " + _vm._s(_vm.data.deliveryOption) + " ")]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "Payment Gateway" } },
                [_vm._v(" " + _vm._s(_vm.data.paymentGateway) + " ")]
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "Grand Total" } },
                [_c("inr", { attrs: { number: _vm.data.grandTotal } })],
                1
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "Amount From Wallet" } },
                [_c("inr", { attrs: { number: _vm.data.amountFromWallet } })],
                1
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "Amount From Card" } },
                [_c("inr", { attrs: { number: _vm.data.amountFromCard } })],
                1
              ),
              _c(
                "el-descriptions-item",
                { attrs: { label: "Amount Received" } },
                [_c("inr", { attrs: { number: _vm.data.amountReceived } })],
                1
              ),
              _c("el-descriptions-item", { attrs: { label: "Comments" } }, [
                _vm._v(" " + _vm._s(_vm.data.comments) + " ")
              ]),
              _vm.data.status === "Not Delivered"
                ? _c(
                    "el-descriptions-item",
                    { attrs: { label: "Not Delivered Reason" } },
                    [_vm._v(" " + _vm._s(_vm.data.notDeliveredReason) + " ")]
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _c("el-divider", { attrs: { "content-position": "left" } }, [
        _vm._v(" Attachments ")
      ]),
      _vm.data
        ? _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _vm._v(" TransportBilty: "),
                  _vm.data.transportBilty
                    ? _c(
                        "el-link",
                        { attrs: { type: "primary", target: "_blank" } },
                        [_vm._v(" view ")]
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _vm._v(" Invoice Attachment: "),
                  _vm.data.invoiceAttachment
                    ? _c(
                        "el-link",
                        { attrs: { type: "primary", target: "_blank" } },
                        [_vm._v(" view ")]
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _vm._v(" Other Attachment: "),
                  _vm.data.otherAttachment
                    ? _c(
                        "el-link",
                        { attrs: { type: "primary", target: "_blank" } },
                        [_vm._v(" view ")]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }