var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-avatar",
    { attrs: { shape: _vm.shape, fit: _vm.fit, size: _vm.size, src: _vm.src } },
    [_c("img", { attrs: { src: "/img/" + _vm.getFileIcon() + ".png" } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }